import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFilm,faVolumeUp,faHistory,faLink,faVolumeMute,faDownload,faUpload } from '@fortawesome/free-solid-svg-icons'

library.add(faFilm);
library.add(faVolumeUp);
library.add(faVolumeMute);
library.add(faHistory);
library.add(faLink);
library.add(faDownload);
library.add(faUpload);


let app=createApp(App).use(router).component('fa', FontAwesomeIcon);

app.config.globalProperties.$filters = 
{
        bytes(value,unit="B",fixed=3) 
        {
            if (value>1024*1024*1024*1024) { return((value/(1024*1024*1024*1024)).toFixed(fixed)+' T'+unit)}
            if (value>1024*1024*1024) { return((value/(1024*1024*1024)).toFixed(fixed)+' G'+unit)}
            if (value>1024*1024) { return((value/(1024*1024)).toFixed(fixed)+' M'+unit)}
            else if (value>1024) { return((value/1024).toFixed(fixed)+' K'+unit)}
            else return (value+unit)
        },
        time(value) 
        {
            let s=value % 60;
            value = Math.floor(value/60);
            let m=value % 60;
            value = Math.floor(value/60);            
            let h=value % 24;
            value = Math.floor(value/24); 
            return (value+'d '+h.toString().padStart(2,'0')+':'+m.toString().padStart(2,'0')+':'+s.toString().padStart(2,'0'));
        }

};

app.mount('#app');
