<template>
  <div class="col-lg-3 d-flex align-items-stretch">
      <div class="card">
         <div class="card-header">
            <div class="row">
              <div class="col-sm-12 text-left">
                <h5 class="card-category">{{appName}}</h5>
                 <h2 class="card-title">{{streamDetails.name}}</h2>
              </div>
            </div>
          </div>
        
        <div class="card-body">

          <div class="row no-gutters">
            <div class="col-6"><fa icon="history"  class="icon"/>&nbsp;{{$filters.time(Math.floor(streamDetails.time/1000))}}</div>
          </div>  
          <div class="row no-gutters">
            <div class="col-6"><fa icon="download"  class="icon"/>&nbsp;{{$filters.bytes(streamDetails.bytes_in)}} ({{$filters.bytes(streamDetails.bw_in,'b',1)}}/s)</div>
            <div class="col-6"><fa icon="upload"  class="icon"/>&nbsp;{{$filters.bytes(streamDetails.bytes_out)}} ({{$filters.bytes(streamDetails.bw_out,'b',1)}}/s)</div>
          </div>

          <div class="row no-gutters" >
            <div class="col-12"><fa icon="volume-up" class="icon" />&nbsp;{{$filters.bytes(Math.floor(streamDetails.bw_audio),'b',0)}}/s {{streamDetails.meta.audio.codec}}&nbsp;{{streamDetails.meta.audio.profile}} {{streamDetails.meta.audio.sample_rate}}Hz&nbsp;{{streamDetails.meta.audio.channels}}ch</div>
          </div>
          <div class="row no-gutters">
            <div class="col-12"><fa icon="film"  class="icon" />&nbsp;{{$filters.bytes(Math.floor(streamDetails.bw_video),'b',0)}}/s {{streamDetails.meta.video.codec}}&nbsp;{{streamDetails.meta.video.profile}}&nbsp;{{streamDetails.meta.video.level}} {{streamDetails.meta.video.width}}x{{streamDetails.meta.video.height}}@{{streamDetails.meta.video.frame_rate}}</div>
          </div>

          <div class="row no-gutters">
            <div class="col-12"><fa icon="link" class="icon" />&nbsp;<a :href="streamUrl">{{streamUrl}}</a></div>
          </div>


      

        <video :id="'video_'+idkey" class="videoplayer" autoplay controls></video>
        <div class="row no-gutters">
          <div class="col-10" >
            <div :id="'meter_'+idkey" class="vumeter"> </div>
          </div>  
          <div class="col-2" >
            <button v-if="!muted" @click="mute" class="btn btn-sm btn-danger "><fa icon="volume-up" /></button>
            <button v-if="muted" @click="mute" class="btn btn-sm btn-secondary"><fa icon="volume-up" /></button>
          </div>
        </div>
      
        </div>
      </div>
  </div>
</template>

<script>

import Hls from "hls.js";
//import webAudioPeakMeter from 'web-audio-peak-meter';


import {createMeter} from '../utils/meter.js';




export default {
  name: 'StreamViewer',
  props: {
    streamDetails:Object,
    appName:String,
  },
  data: () => (
    {
      audioSource:null,
      audioCtx:null,
      muted:true
    }
  ),  
  computed: 
  {
    streamUrl:function() { return ('//edge204.publicwire.eu/hls/'+this.appName+'/'+this.streamDetails.name+'.m3u8');},
    idkey:function() { return(this.appName+'_'+this.streamDetails.name);}
  },
  methods: 
  {
    mute:function()
    {
      this.muted = ! this.muted;
      if (this.muted)
      {
        this.audioSource.disconnect(this.audioCtx.destination);
      }
      else
      {
        this.audioSource.connect(this.audioCtx.destination);
      }
    }
  },
  mounted: function()
    {
       const video = document.getElementById('video_'+this.idkey);
       const videoSrc = this.streamUrl;
       const config=
       {
        autoStartLoad: true,
       };
       if (Hls.isSupported()) 
       {
        var hls = new Hls(config);
        hls.loadSource(videoSrc);
        hls.attachMedia(video);
       }
       else if (video.canPlayType('application/vnd.apple.mpegurl')) 
       {
         video.src = videoSrc;
         video.load();
         video.play();
       }
       this.audioCtx = new AudioContext();
       this.audioSourceRaw = this.audioCtx.createMediaElementSource(video);
       this.audioSource=this.audioCtx.createGain();
       this.audioSourceRaw.connect(this.audioSource);
       this.audioSource.gain.value=1;


       createMeter('meter_'+this.idkey,this.audioCtx,this.audioSource);

       video.addEventListener('play', function() { this.audioCtx.resume(); }.bind(this));
       
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

.icon 
{
  color:white;
}

.videoplayer
{
  width:100%;
  aspect-ratio: 1.666;
  border:1px solid #1d8cf8;
}
.vumeter
{
  width: 100%; 
  height: 2em; 
  
}

</style>
