<template>

<div class="wrapper">
  <div id="style-3" class="sidebar">
      <div  class="sidebar-wrapper">
        <div class="logo"><a href="/" class="simple-text logo-normal">Stream Monitor</a></div>
          <ul class="nav">
            <li class="nav-item active router-link-active"><a href="#/" aria-current="page" class="nav-link"><i class="tim-icons icon-video-66"></i><p>Streams</p></a></li>
          </ul>
        </div>
  </div>


  <div class="main-panel" v-if="!appStarted">
    <div class="content text-center" >
          <button class="btn btn-lg btn-primary"  @click="startApp"><i class="tim-icons icon-triangle-right-17"></i> Start</button>
    </div>
    
  </div>

  <div class="main-panel" v-if="appStarted">


    <nav class="navbar navbar-expand-lg navbar-absolute navbar-transparent">
      <div class="container-fluid">
        <div class="navbar-wrapper">
          <div class="navbar-toggle d-inline">
            <button type="button" aria-label="Navbar toggle button" class="navbar-toggler"><span class="navbar-toggler-bar bar1"></span><span class="navbar-toggler-bar bar2"></span><span class="navbar-toggler-bar bar3"></span></button>
           </div>
           <a href="#" class="navbar-brand">Dashboard</a>
        </div>
           <button type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-label="Toggle navigation" class="navbar-toggler"><span class="navbar-toggler-bar navbar-kebab"></span><span class="navbar-toggler-bar navbar-kebab"></span><span class="navbar-toggler-bar navbar-kebab"></span></button>
           <div class="collapse navbar-collapse show" style="display: none;">
              <ul class="navbar-nav ml-auto">
                <label id="0" class="btn btn-lg btn-primary btn-simple active"><input type="checkbox" name="serverStats" autocomplete="off" v-model="showServerStats"> Server Stats </label>
                <label id="1" class="btn btn-lg btn-primary btn-simple active"><input type="checkbox" name="streams" autocomplete="off" v-model="showStreams"> Streams </label>
              </ul>
        </div>
      </div>
    </nav>

    <div class="content">
      <div >
        <div v-if="(streamStats != null)">
          <div v-for="(server) of streamStats" :key="server.name" > 
            <div class="row"  v-show="showServerStats">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-sm-6 text-left">
                        <h5 class="card-category">Server Statistics</h5>
                        <h2 class="card-title">{{server.server.name}}</h2>
                        </div>
                      </div>
                  </div>
                  <div class="card-body">

                      <div class="table">
                          <table class="table">
                            <tbody class="">

                                <tr>
                                  <td>nginx version:&nbsp;{{server.streamStats.nginx_version}}</td>
                                  <td>nginx RTMP version:&nbsp;{{server.streamStats.nginx_rtmp_version}}</td>
                                  <td>Uptime:&nbsp;{{$filters.time(server.streamStats.uptime)}}</td>
                                  <td>Process ID:&nbsp;{{server.streamStats.pid}}</td>
                                </tr> 
                                <tr> 
                                  <td>Bandwitdh In:&nbsp;{{$filters.bytes(server.streamStats.bw_in,'b',1)}}/s</td>
                                  <td>Bytes In:&nbsp;{{$filters.bytes(server.streamStats.bytes_in)}}</td>
                                  <td>Bandwith Out:&nbsp;{{$filters.bytes(server.streamStats.bw_out,'b',1)}}/s</td>
                                  <td>Bytes Out:&nbsp;{{$filters.bytes(server.streamStats.bytes_out)}}</td>
                                </tr>



                          
                            </tbody>
                          </table>
                        </div>
                  </div>
                </div>  
              </div>
            </div>
            <div class="row"  v-show="showStreams">
              <template v-for="(app) of server.streamStats.application" :key="app.name"  >
                <stream-viewer v-for="(stream) of app.stream" :key='stream.name' :stream-details="stream" :app-name="app.name" >
                </stream-viewer>             
              </template>
            </div>
          </div>
        </div>  
      </div>
    </div>



  </div> 
</div>   
</template>

<script>


const firstTimeout=1000;
const rereadTimeout=10000;






// @ is an alias to /src
import StreamViewer from '@/components/StreamViewer.vue'

export default {
  name: 'StreamsViewer',
  components: {
    StreamViewer
  },
  data: () => (
    {
      appStarted:false,
      streamStats:{},
      showServerStats:true,
      showStreams:true,
      statsUrl:'https://stream-monitor.publicwire.eu/stat',
    }
  ),  
  methods: 
  {
   startApp: function()
   {
     this.appStarted=true;
   } ,
   loadData: function() 
    {
      //console.log('this.url',this.statsUrl);
      fetch(this.statsUrl)
        .then(response => response.json())
        .then(data => 
            {
              //console.log(data);
              this.streamStats=data;
              //console.log(this.streamStats);
              setTimeout(()=>this.loadData(this.statsUrl),rereadTimeout);
            });
    }
  },
  mounted: function ()  
  {
    setTimeout(function() { this.loadData()}.bind(this),firstTimeout);
  }
 
}
</script>

<style >




</style>
