function createVUMeter(element)
{
    //element.style.border = '1px solid green';
    element.style.backgroundColor = 'black';
    element.style.position = 'relative';
    element.style.display='flex';
    this.bar=document.createElement("div");
    this.value=document.createElement("div");
    this.value.style.width="5em";
    this.value.style.height='100%';
    this.value.style.position='absolute;'
    this.value.style.right=0;
    this.value.style.color='white';
    this.value.style.fontSize='0.6em';
    this.value.textContent='N/A';
    this.bar.style.width='100%';
    this.bar.style.height='50%';
    this.bar.style.backgroundImage='linear-gradient(to left, red 1%, #ff0 16%, lime 45%, #080 100%)';
    this.bar.style.position='relative';
    element.appendChild(this.bar);
    element.appendChild(this.value);

    this.mask = document.createElement("div");
    this.mask.style.backgroundColor='black';
    this.mask.style.height='100%';
    this.mask.style.width='100%';
    this.mask.style.position='absolute';
    this.mask.style.right=0;
    this.mask.style.transition='width 0.1s';
    this.bar.appendChild(this.mask);

}

function updateVUMeter(meter,avgPowerDecibels,peakInstantaneousPowerDecibels)
{
   meter.value.textContent=avgPowerDecibels.toFixed(0)+' dB';
   meter.mask.style.width=((-1*avgPowerDecibels)).toString()+'%';
}

function calculateData(analyser,sampleBuffer,meter)  
{

    analyser.getFloatTimeDomainData(sampleBuffer);

    // Compute average power over the interval.
    let sumOfSquares = 0;
    for (let i = 0; i < sampleBuffer.length; i++) 
    {
      sumOfSquares += sampleBuffer[i] ** 2;
    }
    const avgPowerDecibels = 10 * Math.log10(sumOfSquares / sampleBuffer.length);

    // Compute peak instantaneous power over the interval.
    let peakInstantaneousPower = 0;
    for (let i = 0; i < sampleBuffer.length; i++) 
    {
      const power = sampleBuffer[i] ** 2;
      peakInstantaneousPower = Math.max(power, peakInstantaneousPower);
    }
    const peakInstantaneousPowerDecibels = 10 * Math.log10(peakInstantaneousPower);

    // Note that you should then add or subtract as appropriate to get the _reference level_ suitable for your application.

    // Display value.
    //displayNumber(element, mask, avgPowerDecibels);
    //displayNumber('inst', peakInstantaneousPowerDecibels);
    updateVUMeter(meter,avgPowerDecibels,peakInstantaneousPowerDecibels);

}

export function createMeter(element,context,audioSource)
{
   const splitter = context.createChannelSplitter(2);
   splitter.channelCount = 2;
   splitter.channelCountMode = "explicit";

   audioSource.connect(splitter);

   const analyserL = context.createAnalyser();
   splitter.connect(analyserL,0,0);
   const analyserR = context.createAnalyser();
   splitter.connect(analyserR,1,0);

   // Time domain samples are always provided with the count of fftSize even though there is no FFT involved.
   // (Note that fftSize can only have particular values, not an arbitrary integer.)
   // analyser.fftSize = 2048;
   analyserL.fftSize = 256;
   analyserR.fftSize = 256;
   const sampleBuffer = new Float32Array(analyserL.fftSize);

   const baseElement = document.getElementById(element);
   const divL = document.createElement("div");
   divL.style.height='50%';
   divL.style.width='100%';
   const divR = document.createElement("div");
   divR.style.height='50%';
   divR.style.width='100%';

   const meterL=new createVUMeter(divL);
   const meterR=new createVUMeter(divR);

   baseElement.appendChild(divL);
   baseElement.appendChild(divR);


  function loop() 
  {
    calculateData(analyserL,sampleBuffer,meterL);
    calculateData(analyserR,sampleBuffer,meterR);
    //requestAnimationFrame(loop);
  }
  //loop();
  setInterval(loop,50);

}

 